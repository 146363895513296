import Masonry from 'masonry-layout';

const masonry = (config = {}) => ({
    args: config.args,
    init() {
        this.$nextTick(() => {
            this.$el.masonry = new Masonry(this.$el, this.args);
        });
    },
});

export { masonry as default };
