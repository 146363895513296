import CookieAccept from '@thinktomorrow/cookie-accept';

class Cookiebar {
    constructor(
        containerSelector = '[data-cookiebar]',
        openTrigger = '[data-cookiebar-open]',
        closeTrigger = '[data-cookiebar-close]'
    ) {
        this.container = document.querySelector(containerSelector);
        this.openTriggers = Array.from(document.querySelectorAll(openTrigger));
        this.closeTriggers = Array.from(document.querySelectorAll(closeTrigger));

        this._init();
    }

    _init() {
        if (!this.container) {
            return;
        }

        this.openTriggers.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                this.open();
            });
        });

        this.closeTriggers.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                this.close();
            });
        });
    }

    open() {
        this.container.classList.remove('hidden');
    }

    close() {
        this.container.classList.add('hidden');
    }
}

const initCookieAccept = () => {
    const cookiebar = new Cookiebar();

    // document.addEventListener('CookieExistsOnLoad', () => {
    //     console.log('CookieExistsOnLoad');
    //     console.log(event.detail.cookieValue);
    // });

    document.addEventListener('CookieDoesNotExistOnLoad', () => {
        // console.log('CookieDoesNotExistOnLoad');
        // console.log(event.detail.cookieValue);

        cookiebar.open();
    });

    document.addEventListener('CookieUpdated', () => {
        // console.log('CookieUpdated');
        // console.log(event.detail.cookieValue);

        cookiebar.close();
    });

    window.CookieAccept = new CookieAccept({
        name: 'legal-cookies',
        gtm: { enabled: true },
    });
};

export { initCookieAccept as default };
