import thunderbirdScrollTrigger from './scroll-trigger';
import ScrollTrigger from 'gsap/ScrollTrigger';

const initThunderbird = () => {
    window.Alpine.data('thunderbirdScrollTrigger', thunderbirdScrollTrigger);

    window.addEventListener('thunderbird:structure:changed', () => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 800);
    });
};

export { initThunderbird as default };
