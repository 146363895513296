const el = function (selector, scope = document) {
    return scope.querySelector(selector);
};

const els = function (selector, scope = document) {
    return Array.from(scope.querySelectorAll(selector));
};

/**
 * Event delegation listener
 * inspired by code from sebdedeyne.
 * */
const listen = function (type, selector, callback) {
    document.addEventListener(type, (event) => {
        const target = typeof selector === 'string' ? event.target.closest(selector) : event.target;

        if (target) {
            callback(event, target);
        }
    });
};

/**
 * Push a custom event to GTM dataLayer. We automatically add the
 * is_custom_event value so our default GTM event tag will be fired.
 * e.g. window.pushToDataLayer('contactform_submitted', { category: 'Contact' });
 * @param eventName
 * @param data
 */
const pushToDataLayer = function (eventName, data = {}) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        event: eventName,
        ...data,
    });

    window.dataLayer.push(function () {
        if (Object.keys(data).length < 1) return;

        Object.entries(data).forEach((parameter) => {
            this.set(parameter[0], undefined);
        });
    });
};

const isIE = function () {
    const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); // IE 11

    return msie > 0 || trident > 0;
};

export { el, els, listen, pushToDataLayer, isIE };
