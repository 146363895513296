import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const thunderbirdScrollTrigger = (fieldId, stepId, stepIndex) => ({
    fieldId,
    stepId,
    stepIndex,
    init() {

         ScrollTrigger.create({
            trigger: this.$el,
            start: 'top center',
            end: 'bottom center',
            // markers: true,
            onEnter: () => {
                // console.log('Scrolling down. Element in focus:', this.fieldId, this.stepId, this.stepIndex);

                this.focus();
            },
            onEnterBack: () => {
                // console.log('Scrolling up. Element in focus:', this.fieldId, this.stepId, this.stepIndex);

                this.focus();
            },
        });

        window.addEventListener('thunderbird:field:focus', (event) => {
            if (event.detail.fieldId !== this.fieldId) {
                this.unFocus();
            }
        });
    },
    focus() {

        this.$el.classList.remove('is-blurred');

        this.$dispatch('thunderbird:field:focus', {
            stepId: this.stepId,
            stepIndex: this.stepIndex,
            fieldId: this.fieldId,
        });
    },
    unFocus() {
        this.$el.classList.add('is-blurred');
    },
});

export { thunderbirdScrollTrigger as default };
