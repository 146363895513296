import './sentry';
import './jfs';

// Base scripts
import masonry from './alpine/masonry';
import initCarousels from './carousel';
import initCookieAccept from './cookie-accept';
import initDropdowns from './dropdown';

// Theme scripts
import initMobileNavigation from './mobile-navigation';

// Plugin scripts
import initAsyncForms from '../../../app/Plugins/Form/resources/assets/js/main';
import initThunderbird from '../../../app/Plugins/Thunderbird/resources/assets/js/main';
// import initNotifications from '../../../../../app/Plugins/Notifications/resources/assets/js/notifications';

if (window.Alpine) {
    window.Alpine.data('masonry', masonry);
}

initCookieAccept();
initThunderbird();

window.addEventListener('DOMContentLoaded', () => {
    initCarousels();
    initMobileNavigation();
    initDropdowns();
    initAsyncForms();

    // If we're using Livewire in this project, we don't need to initialize Alpine.js here.
    // Livewire will do this automatically. To make sure Alpine.js works everywhere, we can include the scripts in the
    // layout file. Docs: https://livewire.laravel.com/docs/installation#manually-including-livewires-frontend-assets
});

// Tell Vite to build all files in this directory
import.meta.glob(['../img/**']);
